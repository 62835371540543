import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border-b border-gray-200">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-semibold leading-7 text-left text-white">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#E50914"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#E50914"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-3xl  font-semibold leading-10 text-center tracking-tight">
          Frequently Asked Questions FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="Do I Need a Deposit for My Car Rental?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            For most of our vehicles, A $400 is required when you book with us.
            Your deposit is 75% refundable, as long as the vehicle comes back
            damage, smoke and pet stain free. $100 of the deposit is kept for
            the cleaning fee. A $1000 deposit is required on our Motorhome and
            Exotic sports cars.
          </span>
        </Section>
        <Section
          title="What's Your Cancellation Policy?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            We offer a flexible cancellation policy. Cancel for free up to 7
            days before your pickup time. Cancellations 2-6 days before
            reservation are 50% refundable and please note, cancellations made
            less than 48 hours before pickup are non-refundable.
          </span>
        </Section>
        <Section
          title="What Rental Coverage Options Are Available?"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            At Black Rock Rentals, we have a wide range of coverage options to
            fit everyone's needs, ensuring you get great value. We recommend
            reviewing your options on our coverage page to find the perfect fit
            for your journey.
          </span>
        </Section>
        <Section
          title="How Long is the Minimum Rental Period?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            The minimum rental period is 2 hours, making it convenient for short
            trips and long adventures alike.
          </span>
        </Section>
        <Section
          title="What Documents Do I Need to Rent a Car?"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Please bring a valid drivers license, credit card in your name,
            proof of active full coverage insurance at pickup. If you do not
            have a credit card, we have an option to rent with a debit card or
            cash with larger deposit. If you dont have current full coverage
            insurance, supplemental insurance can be purchased. For more
            information, check our terms and conditions page.
          </span>
        </Section>
        <Section
          title="Are There Age Requirements for Renting a Vehicle?"
          isOpen={openSectionIndex === 6}
          onToggle={() => handleToggle(6)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes. The minimum age to rent a vehicle is 18 years, except for
            Luxury, Premium, Exotic or Sports cars, which require a minimum age
            of 25. Renters aged 18-20 can rent with a daily very young driver
            fee of $50 per day added and age 21-24 can rent with a $25 young
            drivers fee.
          </span>
        </Section>
        <Section
          title="What Payment Options Are Accepted?"
          isOpen={openSectionIndex === 7}
          onToggle={() => handleToggle(7)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            We accept payments via Credit Cards, Debit Cards, Apple Pay, Paypal,
            Venmo & Cash.
          </span>
        </Section>
        <Section
          title="Can I Check Rates Without Making a Reservation?"
          isOpen={openSectionIndex === 8}
          onToggle={() => handleToggle(8)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Absolutely! Browse our website freely to find the best rates and
            options that suit your needs without any obligation to book.
          </span>
        </Section>
        <Section
          title="How Will I Know My Reservation is Confirmed?"
          isOpen={openSectionIndex === 9}
          onToggle={() => handleToggle(9)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            You'll receive a confirmation email and see a confirmation message
            on our website once your booking is successfully made.
          </span>
        </Section>
        <Section
          title="Can I Modify or Cancel My Reservation After Confirmation?"
          isOpen={openSectionIndex === 10}
          onToggle={() => handleToggle(10)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, modifications and cancellations are allowed as long as it
            doesnt interfere with another scheduled reservation. Please remember
            our 7 day cancellation policy for full refunds.
          </span>
        </Section>
        <Section
          title="Can I Book Additional Extras or Equipment Online?"
          isOpen={openSectionIndex === 11}
          onToggle={() => handleToggle(11)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Definitely! You can easily add any extras or equipment you need for
            your trip directly on our website.
          </span>
        </Section>
        <Section
          title="Can I Prepay for My Booking?"
          isOpen={openSectionIndex === 12}
          onToggle={() => handleToggle(12)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, we offer the option to prepay for your booking on our website
            for added convenience.
          </span>
        </Section>
        <Section
          title="How Can I Speed Up the Rental Process?"
          isOpen={openSectionIndex === 13}
          onToggle={() => handleToggle(13)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Accelerate your pick-up by submitting your contact and driver’s
            license, insurance and credit card info before arrival. This step
            ensures we're prepared, making your check-in process faster and more
            efficient.
          </span>
        </Section>
        <Section
          title="What Documents Do I Need to Pick Up My Rental?"
          isOpen={openSectionIndex === 14}
          onToggle={() => handleToggle(14)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Please bring the following to ensure a smooth pick-up:
            <br /> Your reservation confirmation or A valid driver’s license
            <br /> A physical credit card in your name Insurance Card
          </span>
        </Section>
        <Section
          title="Is There a Fee for Additional Drivers?"
          isOpen={openSectionIndex === 15}
          onToggle={() => handleToggle(15)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            No, there is no fee for adding additional drivers to your rental
            agreement but that driver will need to provide a valid driver's
            license. This ensures that all drivers are covered under the rental
            insurance policy.
          </span>
        </Section>
        <Section
          title="What Happens If My Flight Is Delayed?"
          isOpen={openSectionIndex === 16}
          onToggle={() => handleToggle(16)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            We understand travel plans can change. If your flight is delayed,
            please inform us as soon as possible. We'll adjust your pick-up time
            accordingly to ensure your vehicle is ready when you arrive.
          </span>
        </Section>
        <Section
          title="Is Roadside Assistance Available?"
          isOpen={openSectionIndex === 17}
          onToggle={() => handleToggle(17)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, we offer 24/7 roadside assistance for any issues you might
            encounter on the road. Just call our dedicated hotline for immediate
            support.
          </span>
        </Section>
        <Section
          title="How Do I Handle Tolls and Tickets During My Rental?"
          isOpen={openSectionIndex === 18}
          onToggle={() => handleToggle(18)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            All tolls and fees incurred during your rental should be paid
            directly at the time they occur, either on the spot or online. It is
            the guest responsibility to cover the cost for any tolls or tickets
            incurred during their rental period.
          </span>
        </Section>
        <Section
          title="What If I Return the Vehicle Late?"
          isOpen={openSectionIndex === 19}
          onToggle={() => handleToggle(19)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            We understand plans can change, but please note we charge on a
            24-hour basis. If you return the car late without prior
            notification, an additional day's rental fee will be charged as well
            as a late fee. To avoid extra charges, please inform us if your
            return time changes
          </span>
        </Section>
        <Section
          title="What Is Your Fuel Policy?"
          isOpen={openSectionIndex === 20}
          onToggle={() => handleToggle(20)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Our policy is full-to-full. You'll receive your rental car with a
            full tank of fuel and are expected to return it full, helping you
            control your fuel costs and only pay for what you use. If you do not
            return the car full of fuel or with the correct fuel, you will be
            charged for the fuel cost as well as an inconvenience fee.
          </span>
        </Section>
        <Section
          title="What Is the Battery Charge Policy for Electric Vehicles?"
          isOpen={openSectionIndex === 21}
          onToggle={() => handleToggle(21)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            For electric vehicles, we ensure a minimum of 70% battery charge at
            pick-up. Similarly, we expect the vehicle to be returned with at
            least 70% charge. This policy helps us maintain readiness for the
            next customer and supports efficient use of the vehicle
          </span>
        </Section>
        <Section
          title="Are Child Seats Available for Rent?"
          isOpen={openSectionIndex === 22}
          onToggle={() => handleToggle(22)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, for families traveling with young children, we offer child
            seats for rent. Please request this when booking to ensure
            availability.
          </span>
        </Section>
        <Section
          title="What If I Need to Extend My Rental Period?"
          isOpen={openSectionIndex === 23}
          onToggle={() => handleToggle(23)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            If you need to extend your rental, please contact us at least 24
            hours before your scheduled drop-off. This allows us to adjust your
            rental agreement and ensure vehicle availability.
          </span>
        </Section>
        <Section
          title="Can I Return the Vehicle to a Different Location?"
          isOpen={openSectionIndex === 24}
          onToggle={() => handleToggle(24)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Absolutely! We offer the flexibility to return your vehicle at a
            different location from where you picked it up. Please note that a
            one-way fee applies for such returns, allowing us to manage our
            fleet across various locations efficiently. Our list of current one
            way cities and pricing are below:
            <br /> Las Vegas-$300
            <br /> Salt Lake City-$500
            <br /> Phoenix-$500
          </span>
        </Section>
        <Section
          title="Can I Drop Off the Vehicle Outside of Office Hours?"
          isOpen={openSectionIndex === 25}
          onToggle={() => handleToggle(25)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, we understand your travel plans may not always align with our
            office hours. For your convenience, you can return your rental
            vehicle outside of office hours. Simply park the vehicle in our
            designated parking spots and deposit the keys in our secure key box,
            or where an agent has instructed you to.
          </span>
        </Section>
        <Section
          title="What Should I Do If I Accidentally Leave Personal Items in the vehicle?"
          isOpen={openSectionIndex === 26}
          onToggle={() => handleToggle(26)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            If you realize you’ve left personal items in the vehicle after
            drop-off, please contact us as soon as possible. We will make every
            effort to locate and return your belongings. However, Black Rock
            Rentals is not responsible for lost items, so we recommend
            double-checking the vehicle before returning it.
          </span>
        </Section>
        <Section
          title="Is There a Late Return Policy?"
          isOpen={openSectionIndex === 27}
          onToggle={() => handleToggle(27)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            If you anticipate returning the vehicle later than the agreed time,
            please inform us in advance to adjust your rental agreement
            accordingly. Late returns without prior notice may incur additional
            charges, as they can affect the scheduling of rentals for other
            customers.
          </span>
        </Section>
        <Section
          title="What Happens If I Return the Vehicle With Less Fuel Than Required?"
          isOpen={openSectionIndex === 28}
          onToggle={() => handleToggle(28)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Our policy is full-to-full. You'll receive your rental car with a
            full tank of fuel and are expected to return it full, helping you
            control your fuel costs and only pay for what you use. If you do not
            return the car full of fuel or with the correct fuel, you will be
            charged for the fuel cost as well as an inconvenience fee.
          </span>
        </Section>
        <Section
          title="Can I Extend My Rental Period at the Time of Drop-Off?"
          isOpen={openSectionIndex === 29}
          onToggle={() => handleToggle(29)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            If you need to extend your rental period, it's best to contact us
            before your scheduled drop-off time. Extensions are subject to
            vehicle availability and additional charges will apply according to
            our current rates.
          </span>
        </Section>
        <Section
          title="What Inspection Will Be Done Upon Drop-Off?"
          isOpen={openSectionIndex === 30}
          onToggle={() => handleToggle(30)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Upon return, a quick inspection of the vehicle will be conducted to
            check for any new damages and to ensure the fuel policy has been
            adhered to. This process is straightforward and is designed to
            ensure the vehicle's condition is consistent with the rental
            agreement in order to get your deposit refunded.
          </span>
        </Section>
        <Section
          title="Are There Any Fees for Excessive Cleaning?"
          isOpen={openSectionIndex === 31}
          onToggle={() => handleToggle(31)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#9CA3AF]">
            Yes, if the vehicle is returned in a condition that requires
            excessive professional cleaning or has been smoked in, a cleaning
            fee will be charged. To avoid this, please keep the vehicle clean
            and adhere to the no-smoking of any kind policy.
          </span>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
