import React, { Fragment, useEffect, useState } from "react";
import DatesRangeInput from "../DatesRangeInput";
import LocationInput from "../LocationInput";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useTimeoutFn } from "react-use";
import { Transition } from "@headlessui/react";
import { useData } from "data/data-provider";

interface CarsSearchFormProps {
  setStartHours: (hours: string) => void;
  setEndHours: (hours: string) => void;
  endHours: string;
  startHours: string;
  setEndMinutes: (minutes: string) => void;
  setStartMinutes: (minutes: string) => void;
  endMinutes: string;
  startMinutes: string;
  setEndPeriod: (period: string) => void;
  setStartPeriod: (period: string) => void;
  endPeriod: string;
  startPeriod: string;
  setShowModal: (show: boolean) => void;
}

const CarsSearchForm: React.FC<CarsSearchFormProps> = ({
  setStartHours,
  setEndHours,
  endHours,
  startHours,
  setEndMinutes,
  setStartMinutes,
  endMinutes,
  startMinutes,
  setEndPeriod,
  setStartPeriod,
  endPeriod,
  startPeriod,
  setShowModal,
}) => {
  const [fieldNameShow, setFieldNameShow] = useState<
    "locationPickup" | "locationDropoff" | "dates" | "rentalCarTime" | null
  >("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startTime, setStartTime] = useState(`${startHours}:${startMinutes}`); // Default start time
  const [endTime, setEndTime] = useState(`${endHours}:${endMinutes}`); // Default end time
  const [showDialog, setShowDialog] = useState(false);
  const [datestartDate, setDateStartDate] = useState<Date | null>(
    new Date("2023/02/06")
  );
  const [dateendDate, setDateEndDate] = useState<Date | null>(
    new Date("2023/02/23")
  );

  const timeSlots = Array.from({ length: 12 }, (_, i) => {
    const hour = i === 0 ? "12" : String(i).padStart(1, "0");
    return [`${hour}:00`, `${hour}:30`];
  }).flat();

  const periodOptions = ["AM", "PM"];
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pickupLocation,
    dropOffLocation,
    setDropoffLocation,
    setDropoffLocationType,
    dropoffLocationType,
    setSearchedDropoffLocation,
    setPickupLocation,
    setSameDropoffLocation,
  } = useData();

  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-[#140B0B] ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => {
              setFieldNameShow("locationPickup");
            }}
          >
            <span className="text-neutral-400">Pick up</span>
            <span>{locationInputPickUp || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            setShowModal={setShowModal}
            headingText="Pick up?"
            defaultValue={locationInputPickUp}
            onChange={(value) => {
              setLocationInputPickUp(value);
              if (dropoffLocationType === false) {
                setFieldNameShow("locationDropoff");
              } else {
                setFieldNameShow("dates");
              }
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setPickupLocation(null);
    setDropoffLocation(null);
  }, []);

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-[#140B0B] ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => {
              setFieldNameShow("locationDropoff");
            }}
          >
            <span className="text-neutral-400">Drop off</span>
            <span>{locationInputDropOff || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Drop off?"
            setShowModal={setShowModal}
            defaultValue={locationInputDropOff}
            onChange={(value) => {
              setLocationInputDropOff(value);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-[#140B0B] overflow-hidden h-26 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => {
              setFieldNameShow("dates");
            }}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <div className="relative">
            <DatesRangeInput />
            <button
              className="absolute top-2 right-2 p-2"
              onClick={() => setFieldNameShow(null)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="w-5 h-5 text-black dark:text-white"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  };
  const renderInputTime = () => {
    const isActive = fieldNameShow === "rentalCarTime";

    return (
      <div
        className={`w-full bg-[#140B0B] overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => {
              setFieldNameShow("rentalCarTime");
            }}
          >
            {/* <div className="text-neutral-300 dark:text-neutral-400">
              <ClockIcon className="w-5 h-5 lg:w-7 lg:h-7" />
            </div> */}
            <div className="flex-grow text-left">
              <span className="block xl:text-lg font-semibold">
                {`${startHours}:${startMinutes} ${startPeriod}`} -
                {` ${endHours}:${endMinutes} ${endPeriod}`}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {"Pick up - Drop off"}
              </span>
            </div>
          </button>
        ) : (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="d-flex flex-col">
              <div className="p-4 space-x-2 overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-[#140B0B]">
                <div className="grid grid-cols-2 gap-4">
                  {/* Start Time */}
                  <div>
                    <h3 className="text-lg font-medium flex justify-center">
                      Start Time
                    </h3>
                    <div className="grid grid-cols-2">
                      {/* Start Time Column */}
                      <div className="flex flex-wrap overflow-y-auto h-48 hide-scrollbar">
                        {timeSlots.map((time) => (
                          <div
                            key={time}
                            className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                              startTime === time
                                ? "bg-primary-200 text-white"
                                : ""
                            }`}
                            onClick={() => {
                              setStartTime(time);
                              const [hour, minutes] = time.split(":");
                              setStartHours(hour);
                              setStartMinutes(minutes);
                            }}
                          >
                            {time}
                          </div>
                        ))}
                      </div>

                      {/* Start AM/PM Column */}
                      <div className="flex flex-col max-w-fit overflow-y-auto h-48 hide-scrollbar">
                        {periodOptions.map((periodOption) => (
                          <div
                            key={periodOption}
                            className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                              startPeriod === periodOption
                                ? "bg-primary-200 text-white"
                                : ""
                            }`}
                            onClick={() => setStartPeriod(periodOption)}
                          >
                            {periodOption}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* End Time Section */}
                  <div>
                    <h3 className="text-lg font-medium flex justify-center">
                      End Time
                    </h3>
                    <div className="grid grid-cols-2">
                      {/* End Time Column */}
                      <div className="flex flex-wrap overflow-y-auto h-48 hide-scrollbar">
                        {timeSlots.map((time) => (
                          <div
                            key={time}
                            className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                              endTime === time
                                ? "bg-primary-200 text-white"
                                : ""
                            }`}
                            onClick={() => {
                              setEndTime(time);
                              const [hour, minutes] = time.split(":");
                              setEndHours(hour);
                              setEndMinutes(minutes);
                            }}
                          >
                            {time}
                          </div>
                        ))}
                      </div>

                      {/* End AM/PM Column */}
                      <div className="flex flex-col max-w-fit overflow-y-auto h-48 hide-scrollbar">
                        {periodOptions.map((periodOption) => (
                          <div
                            key={periodOption}
                            className={`cursor-pointer py-2 px-4 hover:bg-primary-200 hover:text-white rounded-full ${
                              endPeriod === periodOption
                                ? "bg-primary-200 text-white"
                                : ""
                            }`}
                            onClick={() => setEndPeriod(periodOption)}
                          >
                            {periodOption}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        )}
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="flex justify-center items-center space-x-3">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer ${
            dropoffLocationType === true
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setDropoffLocationType(true);
            setDropoffLocation(pickupLocation);
            setSameDropoffLocation(true);
            setSearchedDropoffLocation(pickupLocation?.name ?? "");
            // dropOffLocationExtras?.options.map((location) => {
            //   if (location.title == pickupLocation?.name) {
            //     setSelectedDropOffLocationExtra(location);
            //   }
            // });
          }}
        >
          Same drop off
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer ${
            dropoffLocationType === false
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setDropoffLocationType(false);
            setSameDropoffLocation(false);
          }}
        >
          Different drop off
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderRadioBtn()}
        {renderInputLocationPickup()}
        {dropoffLocationType === false && renderInputLocationDropoff()}
        {renderInputDates()}
        {renderInputTime()}
      </div>
    </div>
  );
};

export default CarsSearchForm;
