export function formatDateToHumanFirendly(
  dateStr: string,
  timezone: string
): string {
  const date = new Date(dateStr);
  // Format the date to a human-friendly format
  return date.toLocaleString("en-US", {
    timeZone: timezone, // EDT time zone
    weekday: "short", // Full weekday name
    year: "numeric",
    month: "short", // Full month name
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: 'numeric',
    timeZoneName: "short", // Timezone name like "EDT"
  });
}
