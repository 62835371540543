import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const ReadytoDrive: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}  `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-[#E5E7EB]">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-[#9CA3AF] text-justify">
            If you are just arriving we can pick you up at no additional cost.
            Live the experience from the moment you land and we will pick you up
            back wherever YOU want.
          </span>
          <ButtonPrimary
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-md transition-colors px-6 py-3 text-sm sm:text-base font-medium"
          >
            Book a Car
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ReadytoDrive;
