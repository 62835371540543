import React, { FC, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";

import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { useData } from "data/data-provider";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, setIsAuthPanelOpen } = useData();
  const navigate = useNavigate();
  return (
    <div className={`nc-MainNav1 relative bg-[#070A0E66] ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center ">
        <div className="flex justify-start items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>
        <div className="hidden md:flex items-center">
          <Navigation />
        </div>

        {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}

        <div className="flex lg:hidden items-center">
          {/* <SwitchDarkMode /> */}
          <div className="px-0.5" />
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
