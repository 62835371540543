import { AxiosError } from "axios";
import customAxios from "config";

export const applyCoupon = async (
  coupon: any,
  bookingId: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      `/api/bookings/${bookingId}/apply_coupon/`,
      { coupon_name: coupon }
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: 'Coupon not found.'
      };
    }
    return { error: "An unknown error occurred." };
  }
};
export const removeCoupon = async (
  coupon: any,
  bookingId: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      `/api/bookings/${bookingId}/remove_coupon/`,
      { coupon_name: coupon }
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during removing coupon.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
