import { AxiosError } from "axios";
import customAxios from "config";

export const getBonzahProducts = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get<any>(
      `/api/bonza/insurance-options/`,
      {
        params: payload, // Ensures payload is sent as query parameters
      }
    );
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during fetching Bonzah Products.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const getBonzahPDF = async (
  bookingId: any,
  type: string
): Promise<{ data?: any; error?: string }> => {
  try {
    // Specify responseType: 'arraybuffer' to handle binary data (PDF)
    const response = await customAxios.get<any>(
      `api/bonza/bonza-pdf/${bookingId}/${type}/`,
      {
        responseType: "arraybuffer", // This ensures we get binary data (PDF)
      }
    );

    // Return the data (PDF content) as an ArrayBuffer
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during fetching Bonzah Products.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
