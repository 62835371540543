"use client";
import { FC } from "react";
import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
import { HeroSearchFormProps } from "./HeroSearchForm";

export const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className} m-auto md:w-[90%]`}
    >
      <RentalCarSearchForm />
    </div>
  );
};
