import React, { FC, useState } from "react";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { googleSignin, SignInFun } from "api/auth/auth";
import { useData } from "data/data-provider";
import { AuthPages } from "routers";
import { globalJson } from "global/global_json";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@headlessui/react";
export interface PageLoginProps {
  className?: string;
  setAuthPageName: React.Dispatch<React.SetStateAction<AuthPages>>;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "", setAuthPageName }) => {
  const navigate = useNavigate();

  const redirectUrl = sessionStorage.getItem("redirectUrl");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUser, setIsAuthPanelOpen, selectedCar } = useData();

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      email,
      password,
      company:
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id,
    };
    const response = await SignInFun(payload);

    if (response.error) {
      toast.error(response.error);
      setLoading(false);
    } else {
      toast.success("Signin Successful!");
      setLoading(false);
      setIsAuthPanelOpen(false);
      if (response.data) {
        localStorage.setItem("access_token", response?.data?.access_token);
        localStorage.setItem("refresh_token", response?.data?.refresh_token);
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
        if (selectedCar != null) {
          navigate("/listing-car-detail");
          setLoading(false);
        } else {
          // navigate("/");
          setLoading(false);
        }
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (response: any) => {
      try {
        console.log(response);
        const headers = { Authorization: `Bearer ${response.access_token}` };
        const res = await fetch(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: headers,
          }
        );
        console.log(res);
        if (res.ok) {
          // Parse the JSON body to get the user information
          const userInfo: any = await res.json();

          const guser: object = {
            email: userInfo.email,
            name: userInfo.name,
            picture: userInfo.picture,
            role: 3,
            sub: userInfo.sub,
            access_token: response.access_token,
            company:
              process.env.REACT_APP_ENV !== "prod"
                ? globalJson.dev_company_id
                : globalJson.prod_company_id,
          };
          const payload = {
            body: JSON.stringify(guser),
          };
          const response1: any = await googleSignin(guser);
          console.log(response1);
          localStorage.setItem("access_token", response1?.access_token);
          localStorage.setItem("refresh_token", response1?.refresh_token);
          localStorage.setItem("user", JSON.stringify(response1));
          setUser(response1);
          toast.success("Login Successful");
          // navigate("/");
          setIsAuthPanelOpen(false);
        } else {
          toast.error("Invalid Information!");
          console.error("Failed to fetch user info:", res.statusText);
          setIsAuthPanelOpen(false);
        }
      } catch {}
    },
  });

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="popup-container mb-16">
        <h2 className="my-5 lg:my-10 flex items-center justify-center text-left font-montserrat font-bold text-3xl lg:text-5xl leading-tight border-b-2 pb-4">
          Sign In
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3 ">
            {loginSocials.map((item, index) => (
              <div
                key={index}
                onClick={() => login()}
                className=" cursor-pointer nc-will-change-transform flex w-full rounded-xl  bg-[#1F2937] px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-text-color dark:text-text-color sm:text-sm">
                  {item.name}
                </h3>
              </div>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm  dark:text-text-color dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-white"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSignIn}>
            <label className="block">
              <span className="text-[#ffffff] font-medium text-sm leading-5">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 rounded-xl border dark:border-[#E5E7EB]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-[#ffffff] font-medium text-sm leading-5">
                Password
                <div
                  onClick={() => {
                    setAuthPageName(AuthPages.ForgotPass);
                  }}
                  className="hover:cursor-pointer font-normal text-sm leading-5 text-[#ffffff]"
                >
                  Forgot password?
                </div>
              </span>
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  className="mt-1 w-full pr-10 rounded-xl border dark:border-[#E5E7EB]"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </label>

            <button
              type="submit"
              className="max-h-12 rounded-xl nc-Button relative h-auto inline-flex items-center justify-center transition-colors bg-primary-200 lg:px-12 py-4 px-6 text-[white] font-bold text-sm sm:text-base"
            >
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </form>

          <span className="block text-center text-text-color dark:text-text-color">
            New user? {` `}
            <span
              className="font-bold hover:cursor-pointer"
              onClick={() => {
                setAuthPageName(AuthPages.Signup);
              }}
            >
              Create an account
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
