import customAxios from "config";
import toast from "react-hot-toast";

export const AddCustomer = async (
  payload: any
): Promise<any> => {
  try {
    // Make the API call using customAxios
    const response = await customAxios.post<any>(
      "/api/company/add-website-customer/",
      payload
    );
    return { data: response.data };
  } catch (error) {
    console.error("AddCustomer API call failed:", error);
    return;
  }
};
