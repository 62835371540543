"use client";

import { HeroSearchForm } from "./HeroSearchForm.1";

export type SearchTab = "Cars";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Cars";
}

export default HeroSearchForm;
