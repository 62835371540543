import React from "react";
import HeroSearchForm2Mobile from "./HeroSearchForm2Mobile";
import HeroSearchForm2RealEstateMobile from "./HeroSearchForm2RealEstateMobile";
import { PathName } from "routers/types";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";

const PAGES_REAL_ESTATE: PathName[] = [
  "/home-2",
  "/listing-real-estate",
  "/listing-real-estate-map",
];

const HeroSearchForm2MobileFactory = () => {
  const pathname = useLocation().pathname;
  if (pathname === "/") {
    return <HeroSearchForm2Mobile />;
  }
  return (
    <div className=" md:hidden justify-center items-center">
      <Link to="/">
        <img width={100} height={100} src={Logo}/>
      </Link>
    </div>
  ); // Or return a default component if none of the conditions match
};

export default HeroSearchForm2MobileFactory;
